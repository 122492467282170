import { ParsedUrlQuery } from 'querystring';
import { kebabCase, snakeCase } from 'lodash';
import {
  KrollReportSupportOptInKind,
  ReportSupportPartner,
} from '@/generated/graphql';
import { enumFromStringValue } from '@/utils/enum';
import { ChainType } from './chain';
import { PageType } from './page';
import { ScamCategory } from './scam-categories';

/**
 * Tracks the names of dynamic route segments we need to access
 * from the query object in multiple places in the app
 *
 * Useage:
 * [chainSlug].tsx
 * const { [DynamicRouteSegments.CHAIN_SLUG]: slug } = query;
 */
export enum DynamicRouteSegments {
  CHAIN_SLUG = 'chainSlug',
  CATEGORY_SLUG = 'categorySlug',
  ADDRESS_QUERY = 'addressQuery',
  DOMAIN_QUERY = 'domainQuery',
  PARTNER_SLUG = 'partnerSlug',
}

/**
 * Tracks the names of arguments in the query string that are
 * added as query params (e.g. /search?d=example.com&filter=all)
 *
 * Since query params are global state, setting arguments and fetching
 * arguments using these enum values helps avoid conflicts.
 *
 * NOTE: if a query param name conflicts with a Next dynamic route
 * param name, and both are matched on the url, the dynamic route param
 * will take precedence.
 */
export enum QueryParamNames {
  PAGE = 'page',
  FILTER = 'filter',
  SORT = 'sort',
  PAGE_CONTEXT = 'context',
  CHAIN = 'chain',
  CHAINS = 'chains',
  CATEGORY = 'category',
  CATEGORIES = 'categories',
  BEFORE = 'before',
  SINCE = 'since',
  ADDRESS = 'a',
  DOMAIN = 'd',
  USERNAME = 'username',
  REPORT = 'report',
  ORG = 'org',
  REPORT_ID = 'reportId',
  REPORT_SUPPORT_KIND = 'kind',
}

/**
 * In app routes
 */

export const ABOUT_ROUTE = '/about';
export const FAQ_ROUTE = '/faq';
export const LEADERBOARD_ROUTE = '/leaderboard';
export const GLOSSARY_ROUTE = '/glossary';
export const CONTACT_SUPPORT_ROUTE = '/contact';
export const PARTNER_CONTACT_SUPPORT_ROUTE = '/partner-contact';
export const BROWSE_ALL_ROUTE = '/reports';
export const CHAIN_BROWSE_ROUTE = '/chain';
export const CATEGORY_BROWSE_ROUTE = '/category';
export const SEARCH_ADDRESS_ROUTE = '/address';
export const SEARCH_DOMAIN_ROUTE = '/domain';
export const PROFILE_ROUTE = '/profile';
export const ORG_MYFEED_ROUTE = '/orgfeed';
export const FILE_REPORT_ROUTE = '/report';
export const NOT_FOUND_ROUTE = '/404';

export const TERMS_OF_USE_URL = '/ChainAbuse_TermsofUse.pdf';

/**
 * External links
 */

export const API_DOCS_LINK = 'https://docs.chainabuse.com';
export const GOT_SCAMMED_URL = 'https://help.chainabuse.com/';
export const CHAIN_ABUSE_LINK = 'https://chainabuse.com';
export const TRM_LINK = 'https://www.trmlabs.com';
export const PARTNER_RANSOMWHERE_LINK = 'https://ransomwhe.re/';
export const SAFETY_SUPPORT_CENTER_URL = 'https://safety.chainabuse.com/';
export const API_DOCS_URL = 'https://docs.chainabuse.com/docs';
export const TWITTER_URL = 'https://twitter.com/chainabuse';
export const DISCORD_URL = 'https://discord.gg/NdH58RY9Pu';
export const PRIVACY_POLICY_URL = 'https://www.trmlabs.com/policies/privacy-policy';
export const COOKIE_POLICY_URL = 'https://www.trmlabs.com/policies/cookie-policy';

/**
 * Parse route / query param helpers
 * Uses the enums above to parse the param from the query.
 */

export const getChainFromDynamicRoute = (
  query: ParsedUrlQuery
): ChainType | undefined => {
  const slug = query[DynamicRouteSegments.CHAIN_SLUG];
  return slugIsChainType(slug) ? slug : undefined;
};

export const getChainFromQueryParams = (
  query: ParsedUrlQuery
): ChainType | undefined => {
  const slug = query[QueryParamNames.CHAIN];
  return slugIsChainType(slug) ? slug : undefined;
};

/**
 * Ensures that the passed in query param for chain either does not exist or
 * has a valid chain.
 * Useful for when the chain query param is optional. Examples.
 * /route?chain=BTC -> true
 * /route?chain= -> true
 * /route -> true
 * /route?chain=INVALID -> false
 */
export const isOptionalChainQueryParamValid = (
  query: ParsedUrlQuery
): boolean => {
  const slug = query[QueryParamNames.CHAIN];
  return !slug || slugIsChainType(slug);
};

export const getCategoryFromDynamicRoute = (
  query: ParsedUrlQuery
): ScamCategory | undefined => {
  const slug = query[DynamicRouteSegments.CATEGORY_SLUG];
  return getCategoryForParam(slug);
};

export const getSupportPartnerFromDynamicRoute = (
  query: ParsedUrlQuery
): ReportSupportPartner | undefined => {
  const slug = query[DynamicRouteSegments.PARTNER_SLUG];
  return slugIsString(slug) ? getSupportPartnerFromParam(slug) : undefined;
};

export const getReportSupportKindFromQueryParams = (
  query: ParsedUrlQuery
): KrollReportSupportOptInKind | undefined => {
  const slug = query[QueryParamNames.REPORT_SUPPORT_KIND];
  return slugIsString(slug) ? getReportSupportKindFromParam(slug) : undefined;
};

export const getReportIdFromQueryParams = (
  query: ParsedUrlQuery
): string | undefined => {
  const slug = query[QueryParamNames.REPORT_ID];
  return slugIsString(slug) ? slug : undefined;
};

export const getCategoryFromQueryParams = (
  query: ParsedUrlQuery
): ScamCategory | undefined => {
  const slug = query[QueryParamNames.CATEGORY];
  return getCategoryForParam(slug);
};

export const getAddressFromDynamicRoute = (
  query: ParsedUrlQuery
): string | undefined => {
  const slug = query[DynamicRouteSegments.ADDRESS_QUERY];
  return slugIsString(slug) ? slug : undefined;
};

export const isAddressInDynamicRouteValid = (
  query: ParsedUrlQuery
): boolean => {
  const slug = query[DynamicRouteSegments.ADDRESS_QUERY];
  return slugIsString(slug);
};

export const getAddressFromQueryParams = (
  query: ParsedUrlQuery
): string | undefined => {
  const slug = query[QueryParamNames.ADDRESS];
  return slugIsString(slug) ? slug : undefined;
};

export const getDomainFromDynamicRoute = (
  query: ParsedUrlQuery
): string | undefined => {
  const slug = query[DynamicRouteSegments.DOMAIN_QUERY];
  return slugIsString(slug) ? decodeURIComponent(slug) : undefined;
};

export const isDomainInDynamicRouteValid = (query: ParsedUrlQuery): boolean => {
  const slug = query[DynamicRouteSegments.DOMAIN_QUERY];
  return slugIsString(slug);
};

export const getDomainFromQueryParams = (
  query: ParsedUrlQuery
): string | undefined => {
  const slug = query[QueryParamNames.DOMAIN];
  return slugIsString(slug) ? decodeURIComponent(slug) : undefined;
};

/**
 * Route and slug utils
 */

const appendParams = (pathname: string, params: { [key: string]: string }) => {
  const url = new URL(pathname, 'https://chainabuse.com');
  const searchParams = url.searchParams;
  Object.entries(params).forEach(([key, value]) => {
    searchParams.append(key, value);
  });
  url.search = searchParams.toString();
  return `${url.pathname}${url.search}`;
};

const getSlugForChain = (type: ChainType): string => encodeURIComponent(type);

export const slugIsChainType = (
  slug: string | string[] | undefined
): slug is ChainType => {
  if (Array.isArray(slug) || !slug) {
    return false;
  }
  return Object.values(ChainType).includes(slug as ChainType);
};

export const getRouteForChain = (type: ChainType): string =>
  `${CHAIN_BROWSE_ROUTE}/${getSlugForChain(type)}`;

export const getRouteForReport = (
  reportId: string,
  fromContext?: PageType,
  params?: { [key: string]: string }
): string => {
  const basePath = `/report/${reportId}`;
  if (fromContext) {
    return appendParams(basePath, {
      ...(params && params),
      [QueryParamNames.PAGE_CONTEXT]: fromContext,
    });
  }

  return basePath;
};

export const getRouteForEditReport = (reportId: string): string =>
  `/report/edit/${reportId}`;

export const getUrlForReport = (reportId: string): string =>
  `${CHAIN_ABUSE_LINK}${getRouteForReport(reportId)}`;

/**
 * Takes a category enum and returns a google friendly url slug string;
 * e.g. 'RUG_PULL' -> 'rug-pull'
 */
const getSlugForCategory = (category: ScamCategory): string =>
  kebabCase(category).toLowerCase();

/**
 * Takes an SEO friendly category string and returns the equivalent category enum,
 * if it exists e.g. 'rug-pull' -> 'RUG_PULL'
 *
 * NOTE: for backwards compatibility, the app relies on the fact that passing
 * an old category enum value slug (e.g. 'RUG_PULL') will still  be recognized to
 * return the same enum.
 */
const getCategoryForSlug = (slug: string): ScamCategory | undefined => {
  const value = snakeCase(slug).toUpperCase();
  if (Object.values(ScamCategory).includes(value as ScamCategory)) {
    return value as ScamCategory;
  }
  return undefined;
};

/**
 * Takes a param string and verifies that it maps to a valid category enum.
 * NOTE: this assumes the param is a slug (kebabcase, lowercase)
 */
const getCategoryForParam = (
  param: string | string[] | undefined
): ScamCategory | undefined => {
  if (Array.isArray(param) || !param) {
    return undefined;
  }
  return getCategoryForSlug(param);
};

const getSupportPartnerFromParam = (
  slug: string
): ReportSupportPartner | undefined => {
  const value = snakeCase(slug).toUpperCase();
  return enumFromStringValue(ReportSupportPartner, value);
};

const getReportSupportKindFromParam = (
  slug: string
): KrollReportSupportOptInKind | undefined => {
  const value = snakeCase(slug).toUpperCase();
  return enumFromStringValue(KrollReportSupportOptInKind, value);
};

export const getRouteForCategory = (type: ScamCategory): string =>
  `${CATEGORY_BROWSE_ROUTE}/${getSlugForCategory(type)}`;

export const getRouteForAddressSearch = (
  address: string,
  chain?: ChainType
): string => {
  const searchAddressPath = `${SEARCH_ADDRESS_ROUTE}/${encodeURIComponent(
    address
  )}`;
  return chain
    ? appendParams(searchAddressPath, {
        [QueryParamNames.CHAIN]: getSlugForChain(chain),
      })
    : searchAddressPath;
};

const getSlugForDomain = (domain: string) => encodeURIComponent(domain);

export const getRouteForDomainSearch = (domain: string): string =>
  `${SEARCH_DOMAIN_ROUTE}/${getSlugForDomain(domain)}`;

export const getRouteForProfile = (username: string): string =>
  // encode uri component since username is user supplied
  `${PROFILE_ROUTE}/${encodeURIComponent(username)}`;

export const slugIsString = (
  slug: string | string[] | undefined
): slug is string => {
  return Array.isArray(slug) || !slug ? false : true;
};

export const getRouteForOrganizationMyFeed = (slug: string): string =>
  `${ORG_MYFEED_ROUTE}/${slug}`;
